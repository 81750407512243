import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import SplitType from 'split-type';

jQuery(document).ready(function($) {
	var windowWidth = $(window).width();
/* ====================== Mega Menu ======================  */
	$('.main-nav a[data-identifier]').on('click', function(e) {
		e.preventDefault();
		var identifier = $(this).attr('data-identifier');
		$('body').addClass('noscroll');
		$('.mega-menu[data-identifier=' + identifier +']').addClass('active');
	});

	$('.close-menu').on('click', function() {
		$('.mega-menu').removeClass('active');
		$('body').removeClass('noscroll');
	});

	$('.mega-menu .menu-item:first-of-type').addClass('active');
	var itemRelated = $('.mega-menu .menu-item:first-of-type').attr('item-related');
	$('.mega-menu [item-related='+ itemRelated +']').addClass('active');

	$('.mega-menu .menu-item').on('mouseover', function() {
		var itemRelated = $(this).attr('item-related');
		$('.mega-menu .menu-item').removeClass('active');
		$('.mega-menu .cta, .mega-menu .grid-item').removeClass('active');
		$('.mega-menu [item-related='+ itemRelated +']').addClass('active');
	});

// /* ====================== Variation Description ======================  */
//      $('.submit-btn').on('click', function() {
//     	$(this).parents('.product-builder').find('.single_add_to_cart_button').click();
//     	$('.variations_form cart').trigger("reset");
//     });

//    // Set quantity input field value to null on document ready
//     $('input.qty').val('');
//     $('.variations').on
//     $(document).on('change', '.variations select', function() {
//   	var variation_desc = $('.woocommerce-variation-description').text();
//   	var variation_price = $('.woocommerce-variation-price').html();

//   	setTimeout(function() {
// 	  	  if($('.single_variation_wrap .woocommerce-variation').attr('style').length > 0) {
// 	  	  	$('.variations-details').fadeOut();
// 	  	  	$('.submit-btn').removeClass('active');
// 	  	} else {
// 	  		$('.variations-details').fadeIn();
// 	  		$('.variation-price').html(variation_price);
// 	  		$('.variation-description').html(variation_desc)
// 	  		$('.submit-btn').addClass('active');
// 	  	}
//   	}, 250);
// });
//     //woocommerce-variation-description
/* ====================== MOBILE NAV ======================  */
    $('.menu-toggle').on("click", function() {
        $(this).toggleClass('active');
        $('header nav').toggleClass('open');
        $('header').toggleClass('open');
        $('body').toggleClass('noscroll');
    	$('header .menu-item-has-children').removeClass('open');
    	$('.sub-menu').slideUp();
    })

    if(windowWidth < 1280) {
	    $('#menu-main-menu .menu-item-has-children').on("click", function() {
	       $(this).toggleClass('open');
	       $(this).find('.sub-menu').slideToggle();
	    })

	    $('.sub-menu a').on("click", function(event) {
	        event.stopPropagation();
	    });
	}

/* ====================== Init Fancybox ======================  */
	Fancybox.bind("[data-fancybox]", {
	});

/* ====================== Testimonial Carousel ======================  */
	const testimonial_carousel = new Swiper('.testimonials-carousel.swiper', {
       	slidesPerView: 1.25,
       	speed: 600,
       	loop: true,
       	watchSlidesProgress: true,
       	spaceBetween: 8,
       	centeredSlides: false,
       	autoPlay: true,
		navigation: {
			nextEl: '.call-to-action  .left-nav',
		},
		breakpoints: {
			768: {
				slidesPerView: 2,
				spaceBetween: 30,
			},
		},
	});

/* ====================== Product ======================  */
	const product_nav_carousel = new Swiper('.product-nav-carousel.swiper', {
       	loop: true,
       	watchSlidesProgress: true,
       	autoPlay: false,
       	spaceBetween: 20,
       	slidesPerView: 3,
       	watchSlidesProgress: true,
	});

	const main_product_carousel = new Swiper('.main-product-carousel.swiper', {
       	slidesPerView: 1,
       	loop: true,
       	thumbs: {
        	swiper: product_nav_carousel,
      	},
	});
/* ====================== Submit Gravity Form ======================  */
	$(document).on('gform_post_render', function(){
	     $('.gform_wrapper .btn').on('click', function() {
        	$(this).parents('.gform_wrapper').find('input[type=submit]').click();
        });
	});

/* ====================== GSAP ======================  */
	let animate = gsap.utils.toArray('.animate')
	animate.forEach((item, index) => {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: item,
				once: true,
				start:"0 80%",
				toggleActions:"restart none none reset",
				end: "500",
				markers: false,
				toggleClass: 'animateelement'
			}
		});
	});

	/* ====================== Splitlines ======================  */
	const cta = new SplitType('.btn span', { types: 'chars' })
	cta.chars
});
